import { Link } from 'react-router-dom';
import { routes } from '../../../../../../constants/routes';
import { isRequestNone, isRequestSuccess, isRequesting } from '../../../../../../utils';
import { Preloader } from '../../../../../common/Preloader';
import { Popup, PopupBody, PopupFooter } from '../../../../../controls';
import { EmailPreferencesTab } from '../../EmailPreferencesContent';
import { SavePortfolioAlertsButton } from './SavePortfolioAlertsButton';
import { usePageConfig } from '../../../../../common/pageConfig/usePageConfig';
import { PageConfigType } from '../../../../../../types/page-config/PageConfigType';
import { BwicFilterType } from '../../../../../../types/filters/FilterState';
import { PortfolioUserConfigFilter } from '../../../../../../types/user-config/UserConfigFilter';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { useDispatch } from 'react-redux';
import { manageEmailPreferencesActions } from '../../../../../../actions/manage-email-preferences.actions';
import { textLabels } from '../../../../../../constants/text-labels';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { AlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { EmailPreferencesRadioGroup } from '../EmailPreferencesRadioGroup';
import { emailPreferencesUtils } from '../../../../../../utils/email-preferences.utils';
import { AlertEnabledState } from '../../../../../../types/enums/AlertEnabledState';
import { PortfolioFiltersAlertsTable } from './PortfolioFilterAlertsTable';
import { StatusMessageSection } from '../../../../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../../../../types/state/NotificationState';

interface Props {
    onClose: () => void;
}

export const PortfolioAlertsPopup = ({ onClose }: Props) => {
    const dispatch = useDispatch();

    const { config, requestState, updateRequestState } = usePageConfig(PageConfigType.Portfolio, true, BwicFilterType.Portfolio);
    const requestStateGetPreferences = useAppSelector(s => s.manageEmailPreferences.requestStateGetPreferences);
    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);
    const portfolioIssuanceMonitorAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioIssuanceMonitorAlertState,
    );
    const portfolioRollerDeadlineAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioRollerDeadlineAlertState,
    );
    const allSecuritiesBwicAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioBwicAlertState,
    );
    const allSecuritiesInventortAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioDealersInventoryAlertState,
    );

    const allSecuritiesBwicAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        allSecuritiesBwicAlertState,
        AlertOption.Instant,
    );
    const allSecuritiesInventoryAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        allSecuritiesInventortAlertState,
        AlertOption.Instant,
    );
    const issuanceMonitorAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioIssuanceMonitorAlertState,
        AlertOption.Instant,
    );
    const rollerDeadlineAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioRollerDeadlineAlertState,
        AlertOption.Instant,
    );

    useEffect(() => {
        if (isRequestSuccess(savePreferencesRequestState)) {
            onClose();
        } // eslint-disable-next-line
    }, [savePreferencesRequestState]);

    useEffect(
        () => () => {
            dispatch(manageEmailPreferencesActions.reset());
        },
        [dispatch],
    );

    useEffect(() => {
        if (isRequestNone(requestStateGetPreferences)) {
            dispatch(manageEmailPreferencesActions.getInitialDataRequest());
        }
    }, [dispatch, requestStateGetPreferences]);

    const handleChangeImAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioIssuanceMonitorAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleChangeRollerDeadlineAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioRollerDeadlineAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleBwicAlertChange = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioBwicAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleInventoryAlertChange = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioInventoryAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    return (
        <Popup
            renderInBody
            title="Portfolio Alerts"
            modalClass="modal-email-preferences email-preferences-fixed-height modal-portfolio-alerts"
            onClose={onClose}
        >
            <PopupBody>
                <Preloader inProgress={!isRequestSuccess(requestState, requestStateGetPreferences)}>
                    <div className="page-content-profile">
                        {(portfolioIssuanceMonitorAlertState === AlertEnabledState.PartiallyEnabled ||
                            portfolioRollerDeadlineAlertState === AlertEnabledState.PartiallyEnabled ||
                            allSecuritiesBwicAlertState === AlertEnabledState.PartiallyEnabled ||
                            allSecuritiesInventortAlertState === AlertEnabledState.PartiallyEnabled) && (
                            <StatusMessageSection type={StatusMessageSectionType.Info}>
                                Some alerts are disabled on partial check, see Portfolio page for details and management.
                            </StatusMessageSection>
                        )}
                        <div className="profile-content">
                            <div className="notifications-center">
                                <EmailPreferencesRadioGroup
                                    value={allSecuritiesBwicAlertOption}
                                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                                    alertOptionInfo={{
                                        [AlertOption.Instant]: textLabels.savedFiltersPortfolioBwicInstantAlert,
                                    }}
                                    groupNameIndentifier="single-portfolio-bwic-alert-state"
                                    title="BWIC Alerts"
                                    description={textLabels.savedFiltersPortfolioBwicAlertDescription}
                                    onChange={handleBwicAlertChange}
                                    partially={allSecuritiesBwicAlertState === AlertEnabledState.PartiallyEnabled}
                                />
                                <EmailPreferencesRadioGroup
                                    value={allSecuritiesInventoryAlertOption}
                                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                                    alertOptionInfo={{
                                        [AlertOption.Instant]: textLabels.saveFiltersPortfolioInventoryInstantsAlert,
                                    }}
                                    groupNameIndentifier="single-portfolio-inventory-alert-state"
                                    title="Inventory Alerts"
                                    description={textLabels.savedFiltersPortfolioInventoryAlertDescription}
                                    onChange={handleInventoryAlertChange}
                                    partially={allSecuritiesInventortAlertState === AlertEnabledState.PartiallyEnabled}
                                />
                                <EmailPreferencesRadioGroup
                                    value={issuanceMonitorAlertOption}
                                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                                    alertOptionInfo={{
                                        [AlertOption.Instant]:
                                            textLabels.savedFiltersPortfolioIssuanceMonitorInstantAlert,
                                    }}
                                    groupNameIndentifier="single-portfolio-issuance-monitor-alert-state"
                                    title="Issuance Monitor"
                                    description={textLabels.savedFiltersPortfolioIssuanceMonitorAlertDescription}
                                    onChange={handleChangeImAlert}
                                    partially={
                                        portfolioIssuanceMonitorAlertState === AlertEnabledState.PartiallyEnabled
                                    }
                                    requiredFeatures={[
                                        SubscriptionFeature.IssuanceMonitorFullAccess,
                                        SubscriptionFeature.PortfolioAlerts,
                                    ]}
                                />
                                <EmailPreferencesRadioGroup
                                    value={rollerDeadlineAlertOption}
                                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                                    alertOptionInfo={{
                                        [AlertOption.Instant]: textLabels.savedFiltersPortfolioRollerDeadlineDailyAlert,
                                    }}
                                    groupNameIndentifier="single-portfolio-roller-deadline-alert-state"
                                    title="Roller Deadline"
                                    description={textLabels.savedFiltersPortfolioRollerDeadlineAlertDescription}
                                    onChange={handleChangeRollerDeadlineAlert}
                                    partially={portfolioRollerDeadlineAlertState === AlertEnabledState.PartiallyEnabled}
                                    requiredFeatures={[
                                        SubscriptionFeature.IssuanceMonitorFullAccess,
                                        SubscriptionFeature.PortfolioAlerts,
                                    ]}
                                />
                                <div className="controls-wrap">
                                    <div className="controls-wrap-head">
                                        <h4 className="controls-wrap-title">Saved Filters</h4>
                                    </div>
                                    <p className="controls-wrap-info">
                                        Receive email notifications when security from your portfolio is on a BWIC or
                                        has been added, updated, or traded in the Dealer Inventory List.
                                    </p>
                                    <PortfolioFiltersAlertsTable
                                        disabled={isRequesting(
                                            requestState,
                                            updateRequestState,
                                            savePreferencesRequestState,
                                        )}
                                        savedFilters={(config?.filters as PortfolioUserConfigFilter[]) ?? []}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Preloader>
            </PopupBody>
            <PopupFooter>
                <Link
                    to={routes.profileNotificationsTab(EmailPreferencesTab.Portfolio)}
                    className="btn-link margin-r-a"
                >
                    Manage Alerts
                </Link>
                <button className="btn btn-ghost" onClick={onClose}>
                    Cancel
                </button>
                <SavePortfolioAlertsButton />
            </PopupFooter>
        </Popup>
    );
};
