import { values } from 'lodash';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { AlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { PortfolioSavedFilterAlertsState } from '../../../../../../types/state/ManageEmailPreferencesState';
import { ActionBlocker } from '../../../../../access/ActionBlocker';
import { RadioButton } from '../../../../../controls';
import { PortfolioAlertsTypes } from '../../../../../../types/portfolio/PortfolioAlerts';

interface Props {
    option: AlertOption;
    filterAlertState: PortfolioSavedFilterAlertsState;
    type: PortfolioAlertsTypes;
    onChange: (alertOption: AlertOption, type: PortfolioAlertsTypes) => void;
    disabled?: boolean;
    requiredFeature?: SubscriptionFeature;
    name?: string;
}
export function PortfolioFiltersAlertsHeaderRadioButton({
    option,
    type,
    filterAlertState,
    onChange,
    disabled,
    name,
    requiredFeature,
}: Props) {
    const filterValues = values(filterAlertState);

    const selected = filterValues?.some(x =>
        type === PortfolioAlertsTypes.bwicAlert ? x.bwicAlert === option : x.inventoryAlert === option,
    );
    const partially = filterValues?.some(x =>
        type === PortfolioAlertsTypes.bwicAlert ? x.bwicAlert !== option : x.inventoryAlert !== option,
    );

    const handleClick = () => {
        onChange(option, type);
    };

    return (
        <ActionBlocker feature={requiredFeature}>
            {blocked => (
                <RadioButton
                    disabled={disabled || blocked}
                    name={name ? name : `global-${option}`}
                    label={option}
                    checked={blocked ? option === AlertOption.Never : selected}
                    partially={!blocked && partially}
                    onClick={handleClick}
                    className="align-top"
                    onChange={() => {}}
                />
            )}
        </ActionBlocker>
    );
}
