import { useDispatch } from 'react-redux';
import { keys } from 'lodash';
import cn from 'classnames';
import { AlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { BwicFilterType } from '../../../../../../types/filters/FilterState';
import { PortfolioUserConfigFilter } from '../../../../../../types/user-config/UserConfigFilter';
import { ListBuilder } from '../../../../../../utils/ListBuilder';
import { SavedFilterDetails } from '../../../../../amrPipeline/aggregated/email-alerts/issuance-monitor/SavedFilterDetails';
import { Table } from '../../../../../bidding/common/table';
import {
    PortfolioAlertsRowType,
    PortfolioSavedFilterAlertsState,
} from '../../../../../../types/state/ManageEmailPreferencesState';
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { manageEmailPreferencesActions } from '../../../../../../actions/manage-email-preferences.actions';
import { useEffect } from 'react';
import { apiUtils } from '../../../../../../utils';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { user } from '../../../../../../user/user';
import { roles } from '../../../../../../constants/roles';
import { SubscribeLink } from '../../../../../access/BlockedFeatureText';
import { RequiredFeature } from '../../../../../access/RequiredFeature';
import { portfolioFilterAlertsColumns } from './portfolioFilterAlertsTableColumns';
import { PortfolioAlertsTypes } from '../../../../../../types/portfolio/PortfolioAlerts';

export interface IPortfolioAlertRow {
    title: string;
    rowType: PortfolioAlertsRowType;
    bwicAlert: AlertOption | null; // null if partial;
    inventoryAlert: AlertOption | null; // null if partial;
    filterReferenceName: string;
    disabled?: boolean;
}

interface Props {
    savedFilters: PortfolioUserConfigFilter[];
    disabled?: boolean;
}

export const PortfolioFiltersAlertsTable = ({ savedFilters, disabled }: Props) => {
    const dispatch = useDispatch();
    const filter = useAppSelector(s => s.filters.portfolio.filter);
    const filtersAlertState = useAppSelector(s => s.manageEmailPreferences.portfolioSavedFilterAlerts);
    const visibleFilters = useAppSelector(s => s.filters.portfolio.visibleFilters);

    const isBd = user.hasRoles(...roles.bd());

    useEffect(() => {
        if (savedFilters.length && !keys(filtersAlertState).length) {
            const initialState: PortfolioSavedFilterAlertsState = apiUtils.normalize(
                savedFilters,
                f => f.referenceName,
                f => ({
                    bwicAlert: (f as PortfolioUserConfigFilter).bwicAlertOption,
                    inventoryAlert: (f as PortfolioUserConfigFilter).dealerInventoryAlertOption,
                }),
            );

            dispatch(manageEmailPreferencesActions.initPortfolioFilterAlerts(initialState));
        }
    }, [savedFilters, filtersAlertState, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(manageEmailPreferencesActions.initPortfolioFilterAlerts({}));
        }
    }, [dispatch]);

    const gitFilterRowTitle = (filter: PortfolioUserConfigFilter) => {
        const filterCount = keys(filter.filter).length;
        const filterWord = filterCount === 1 ? 'filter' : 'filters';
        return `${filter.name} (${filterCount} ${filterWord})`;
    };

    const getTableRows = () => {
        const savedFilterRows: IPortfolioAlertRow[] = savedFilters.map(f => ({
            title: gitFilterRowTitle(f),
            rowType: PortfolioAlertsRowType.SavedFilter,
            bwicAlert: filtersAlertState[f.referenceName]?.bwicAlert ?? AlertOption.Never,
            inventoryAlert: filtersAlertState[f.referenceName]?.inventoryAlert ?? AlertOption.Never,
            filterReferenceName: f.referenceName,
        }));

        return new ListBuilder().add(...savedFilterRows).result();
    };

    const onColumnClick = (type: PortfolioAlertsTypes, alertOption: AlertOption, row: IPortfolioAlertRow) => {
        type === PortfolioAlertsTypes.bwicAlert
            ? dispatch(
                  manageEmailPreferencesActions.portfolioSavedFilterAlertChange(
                      row.filterReferenceName!,
                      alertOption,
                      row.inventoryAlert,
                  ),
              )
            : dispatch(
                  manageEmailPreferencesActions.portfolioSavedFilterAlertChange(
                      row.filterReferenceName!,
                      row.bwicAlert,
                      alertOption,
                  ),
              );
    };

    const onHeaderClick = (option: AlertOption, type: PortfolioAlertsTypes) => {
        type === PortfolioAlertsTypes.bwicAlert
            ? dispatch(
                manageEmailPreferencesActions.portfolioSavedFilterGlobalAlertChange(option),
            )
            : dispatch(manageEmailPreferencesActions.portfolioSavedFilterGlobalAlertChange(undefined, option));
    };

    const renderFilterDetails = (row: IPortfolioAlertRow) => {
        if (row.rowType !== PortfolioAlertsRowType.SavedFilter) return null;

        const savedFilter = savedFilters?.find(sf => sf.referenceName === row.filterReferenceName);

        if (!savedFilter) return null;

        return (
            <SavedFilterDetails
                userFilter={savedFilter!}
                filter={filter}
                visibleFilters={visibleFilters}
                filterType={BwicFilterType.Portfolio}
            />
        );
    };

    return (
        <>
            <Table
                dataItems={getTableRows()}
                createHeaderCustomArgs={() => ({
                    onHeaderClick,
                    filters: filtersAlertState,
                    disabled,
                    isBd
                })}
                createSecurityCustomArgs={() => ({
                    onColumnClick,
                    disabled
                })}
                columns={portfolioFilterAlertsColumns}
                className={cn('data-list-striped data-list-saved-filter', { 'margin-b-0': !savedFilters.length })}
                shouldRenderCollapsibleRowCallback={(row: IPortfolioAlertRow) =>
                    row.rowType === PortfolioAlertsRowType.SavedFilter
                }
                renderCollapsibleItem={renderFilterDetails}
                createSecurityCustomClassName={(row: IPortfolioAlertRow) =>
                    row.rowType !== PortfolioAlertsRowType.SavedFilter && 'row-grouping'
                }
            />
            {!savedFilters.length ? (
                <div className="empty-placeholder">
                    <RequiredFeature
                        inline
                        feature={SubscriptionFeature.PortfolioSavedFilters}
                        text={
                            <>
                                <SubscribeLink /> to unlock the saved filters.
                            </>
                        }
                    >
                        There are no saved filters yet to receive an email notifications.
                    </RequiredFeature>
                </div>
            ) : null}
        </>
    );
};
